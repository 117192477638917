* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: Avenir;
}

html {
    scroll-behavior: smooth;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

body {
    overflow-x: hidden;
    background-image: url("img/background.png");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    line-height: 1.75em;
}
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

p.error {
    color:red;
}

.form-input {
    padding-left: 20px;
    padding-right:20px;
}

.form-input-label {
    height: 24px;
    width: 260px;
    color: #333333;
    font-size: 14px;
    line-height: 24px;
}

.form-input-button {
    border: none;
    background-color: #00735A;
    color: #EDEDED;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.5) 1px 1px 10px;
    min-width: 100px;
    height: 28px;
    font-size: 12px;
    margin-top: 10px;
    cursor: pointer;
}

    .form-input-button:disabled {
        background: #494949;
        color: #9fa3a9;
        cursor: not-allowed;
    }

.form-input-text {
    display: block;
    border: none;
    background-color: #EDEDED;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.3) 1px 1px 10px;
    width: 95vw;
    height: 28px;
    text-align: left;
    font-size: 16px;
}

.form-input-number {
    display: block;
    border: none;
    background-color: #EDEDED;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.3) 1px 1px 10px;
    width: 95vw;
    height: 28px;
    text-align: right;
    font-size: 16px;
}

.form-input-dropdown {
    display: block;
    width: 40vw;
}

.form-input-file {
    display:block;
}

.form-check-box {
    display: block;
    border: none;
    background-color: #EDEDED;
    border-radius: 20px;
    width: 95vw;
    height: 28px;
    text-align: left;
    font-size: 16px;
}

#signUpAlertText {
    border: none;
    background-color: #EDEDED;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.3) 1px 1px 10px;
    width: 95vw;
    height: 80px;
    text-align: left;
    font-size: 16px;
}

.logo {
    padding: 5px 5px 5px 5px;
}


#form input, #contact #text {
    border: none;
    background-color: #EDEDED;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.3) 1px 1px 10px;
    width: 40vw;
    height: 28px;
    text-align: left;
    font-size: 16px;
}

.collapse {
    display: none
}

    .collapse.in {
        display: block
    }

.btn-help {
    margin-top: 10px;
    margin-left: auto;
    margin-right: 20px;
    height: 24px;
    width: 24px;
    float: right;
    cursor: pointer;
}

.qrCode {
    text-align: center;
}

.till-identifier {
    text-align: center;
}

p {
    margin: 0px 20px 0px 20px;
    font-size: 14px
}

.print-logo {
    visibility:hidden;
    height:0;
    width:0;
}

.print-rnr-logo {
    visibility: hidden;
    height:0;
    width:0;
}


.qr-scan-instructions {
    visibility: hidden;
    height:0;
}

@media print {
    @page {
        size: A4;
        margin: .5rem 0;
    }
    div.qrCodePage {
        page-break-after:auto;
        page-break-inside:avoid;
        font-size:12px;
    }

    .form-input-button {
        visibility: hidden;
    }

    .navbar {
        visibility:hidden;
        height:0;
    }
    
    .print-logo {
        visibility:visible;
        height:auto;
        width:auto;
        text-align: left;
        margin: 10px 10px 10px 10px;
        max-width:100%;
    }

    .print-rnr-logo {
        visibility: visible;
        height: auto;
        width: auto;
        text-align: left;
        page-break-before: avoid;
        margin: 10px 10px 10px 10px;
    }

    .qrCode {
        text-align:center;
    }

    .qr-image {
    }

    div.print-logo {
    }

    .qr-scan-instructions {
        visibility:visible;
        height:auto;
        text-align:center;
        font-size:16px
    }

    .till-identifier {
        text-align:center;
    }
}



@font-face {
    font-family: Avenir;
    src: url("fonts/Metropolis-Medium.otf") format("opentype");
}

@font-face {
    font-family: Avenir;
    font-weight: 900;
    src: url("fonts/Metropolis-Bold.otf") format("opentype");
}

@font-face {
    font-family: Avenir;
    font-weight: 700;
    src: url("fonts/Metropolis-SemiBold.otf") format("opentype");
}

.amplify-tabs {
    display: none;
}

.amplify-button[data-variation='primary'] {
    border: none;
    background-color: #00735A;
    color: #EDEDED;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.5) 1px 1px 10px;
    width: 100px;
    height: 28px;
    font-size: 12px;
    margin-top: 10px;
    cursor: pointer;
}

.amplify-input[data-variation='primary'] {
    display: block;
    border: none;
    background-color: #EDEDED;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.3) 1px 1px 10px;
    width: 95vw;
    height: 28px;
    text-align: left;
    font-size: 16px;
}